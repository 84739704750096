import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

export const WhatPeopleSayAboutUs = () => {
  const feedbacks = [
    {
      icons: "",
      title: "Verify Receiver Details. 🔍👀",
      body: "Always double-check recipient information before sending money. A small confirmation step can save you from big remittance mishaps.",
    },
    {
      icons: "",
      title: "Stay Wary of Suspicious Requests! 🕵‍♂🔍",
      body: "If someone asks for money unexpectedly or urgently, take a moment to investigate. Fraudsters use urgency to catch you off guard, so stay vigilant.",
    },
    {
      icons: "",
      title: "Stay Alert! 🛡🔒",
      body: "Never expose your PIN or passwords to anyone. ",
    },
  ];
  return (
    // <div className="what-people-say-about-us-section">
    //   <div className="section-text text-white py-3 container">
    //   Borga tips💬
    //   </div>
    //   <div className="feedback-cards-scroll-container">
    //     <Row className="d-flex flex-nowrap feedback-card-row-container align-items-stretch gx-5 mb-5">
    //       {feedbacks.map((data, key) => (
    //         <Col md={4} key={key} className="feedback-card-col">
    //           <Card className="feedback-cards border-0 h-100 ">
    //             <Card.Body className="feedback-cards-body pb-4 px-4 fs-5 fw-light d-flex flex-column">
    //               <div className="fs-4 mt-3 fw-bold">{data.title}</div>
    //               <div className="mt-3 pb-3">
    //                 {data.body}
    //               </div>
    //             </Card.Body>

    //           </Card>
    //         </Col>
    //       ))}
    //     </Row>
    //   </div>
    // </div>
    <Container className="py-4" style={{ backgroundColor: "##1C1C1C" }}>
      <div className="section-text text-white py-3 container">Borga tips💬</div>
      <Swiper
        spaceBetween={35}
        pagination={{ clickable: true }}
        slidesPerView={3}
        navigation={true}
        autoplay={{ delay: 10000, disableOnInteraction: false }}
        modules={[Pagination, Navigation, Autoplay]}
        breakpoints={{
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          375: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          575: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          767: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
        }}
        className=""
      >
        <Row>
          {feedbacks.map((data, key) => (
            <SwiperSlide key={key}>
              <Col className="pb-5">
                <Card
                  className="mb-4 mb-lg-0 rounded-4"
                  style={{ backgroundColor: "#303030", height: "20rem" }}
                >
                  <Card.Body className=" p-4">
                    <h4 className="mb-4 text-white fw-semibold tips-header">
                      {data.title}
                    </h4>
                    <p className="mb-0 text-white  fw-light" style={{fontSize:"18px"}}>
                      {data.body}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>
          ))}
        </Row>
      </Swiper>
    </Container>
  );
};
