// import { Image } from "react-bootstrap";
// import { LogoDark } from "../../../assets/images/index";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Offcanvas from "react-bootstrap/Offcanvas";
// // import { useNavigate } from "react-router-dom";
// import { HashLink as Link } from 'react-router-hash-link';

// // import { useState } from "react";

// function Header() {

//   return (
//     <>
//       {["lg"].map((expand) => (
//         <Navbar key={expand} expand={expand}>
//           <Container className="py-4 border-bottom">
//             <Navbar.Brand as={Link} to="/home#home">
//               <Image src={LogoDark} width="40%" />
//             </Navbar.Brand>
//             <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
//             <Navbar.Offcanvas
//               id={`offcanvasNavbar-expand-${expand}`}
//               aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
//               placement="end"
//               className="p-0 m-0"
//             >
//               <Offcanvas.Header closeButton>
//                 <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
//                   Menu
//                 </Offcanvas.Title>
//               </Offcanvas.Header>
//               <Offcanvas.Body className="d-inline">
//                 <Nav className="justify-content-end flex-grow-1">
//                   <Nav.Link eventKey="/" as={Link} to="/home#" className="">
//                     Home
//                   </Nav.Link>
//                   <Nav.Link
//                     eventKey="/what_is_borga"
//                     as={Link}
//                     to="/home#what-is-borga"
//                     className=""
//                     // onClick={(e) =>
//                     //   scrollToSection(e, "what-is-borga")
//                     // }
//                   >
//                     What is borga?
//                   </Nav.Link>
//                 </Nav>
//               </Offcanvas.Body>
//             </Navbar.Offcanvas>
//           </Container>
//         </Navbar>
//       ))}
//     </>
//   );
// }

// export default Header;

import React, { useState, useCallback } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { LogoDark } from "../../../assets/images/index";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { VscMenu } from "react-icons/vsc";
import { HashLink as Link } from "react-router-hash-link";
import { RiCloseFill } from "react-icons/ri";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const navigateAndScroll = useCallback((target: any) => {
    handleClose();
    window.location.href = `/home${
      target === "what-is-borga" ? "#what-is-borga" : ""
    }`;
    setTimeout(() => {
      const element = document.querySelector(`[href="#${target}"]`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, []);

  return (
    <section>
      <Container className="py-3 mb-4 border-bottom ">
        <Navbar>
          <div className="d-flex justify-content-between text-primary w-100 navbar-text">
            <div>
              <Link to="/home#" className="">
                <Image src={LogoDark} width="40%" />
              </Link>
            </div>
            <div className=" d-none d-sm-flex gap-3 text-primary justify-content-end align-items-center">
              <Nav className="justify-content-end gap-3  flex-grow-1">
                <Link to="/home#" className="text-primary ">
                  Home
                </Link>
                <Link to="/home#what-is-borga" className="text-primary">
                  What is borga?
                </Link>
              </Nav>
            </div>
            <div className="d-block d-sm-none ">
              <Button
                variant="light"
                onClick={() => setShow(!show)}
                className="me-2 border "
              >
                {show ? (
                  <div className="dropdown-content shadow-lg">
                    <div className="d-flex justify-content-end p-2">
                      <button className="card p-2 d-flex justify-content-end text-end ">
                        {" "}
                        <RiCloseFill />{" "}
                      </button>
                    </div>
                    <Nav className="d-block px-3 py-2 navbar-text text-start mb-3 ">
                      <div className="navbar-text">
                        <Link to="/home#" className="text-primary">
                          Home
                        </Link>
                      </div>
                      <div className="my-3">
                        <Link to="/home#what-is-borga" className="text-primary">
                          What is borga?
                        </Link>
                      </div>
                      <div className="my-3">
                        <Link to="/faqs#contact-us" className="text-primary">
                          Contact us
                        </Link>
                      </div>
                      <div className="my-3">
                        <Link to="/faqs#faqs" className="text-primary">
                          FAQs
                        </Link>
                      </div>
                      <div className="my-3">
                        <Link to="/privacy#privacy" className="text-primary">
                          Privacy
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="/terms-conditions#ts-cs"
                          className="text-primary"
                        >
                          Ts & Cs
                        </Link>
                      </div>
                    </Nav>
                  </div>
                ) : (
                  <VscMenu />
                )}
              </Button>
            </div>
          </div>
        </Navbar>
        {/* <div>
          {show ? (
            <Card className="d-sm-none d-block justify-content-end align-items-end" style={{width:"300px"}}>
              <Nav className="d-block px-3 py-2 ">
                <div className="">
                  <Link to="/home#" className="text-primary">
                    Home
                  </Link>
                </div>
                <div>
                  <Link to="/home#what-is-borga" className="text-primary">
                    What is borga?
                  </Link>
                </div>
              </Nav>
            </Card>
          ) : (
            ""
          )}
        </div> */}
      </Container>
    </section>
  );
}

export default Header;
