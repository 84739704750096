import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
  BillIcon,
  MastercardColor,
  MerchantIcon,
  MoneyIcon,
  PayBills,
  PayMerchant,
  SendMoney,
  VisaColor,
  VisaMastercard,
} from "../../../assets/images/index";
import { LuShoppingBag } from "react-icons/lu";
import { IoMdArrowForward } from "react-icons/io";
import { HiOutlineClipboardList } from "react-icons/hi";
import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

export const WhyBorga = () => {
  const cardData = [
    {
      icons: <LuShoppingBag className="text-black  icon-display fs-3" />,
      title: "Pay merchants",
      body: "Make direct payments to your favourite merchants back home in Africa.",
      image: `${PayMerchant}`,
      icon: `${MerchantIcon}`,
    },
    {
      icons: <IoMdArrowForward className="text-black  icon-display fs-3" />,
      title: "Send money",
      body: "Send love across borders with just a tap! Send money to your loved ones back home in Africa, quickly and securely.",
      image: `${PayBills}`,
      icon: `${BillIcon}`,
    },
    {
      icons: (
        <HiOutlineClipboardList className="text-black  icon-display fs-3" />
      ),
      title: "Pay bills",
      body: <>Pay the bills of your loved ones back home in Africa. </>,
      image: `${SendMoney}`,
      icon: `${MoneyIcon}`,
    },
  ];

  return (
    <div className="why-borga-card-section">
      <Container>
        <div className="container section-text">Why Borga ? </div>
        <Swiper
          spaceBetween={35}
          pagination={{ clickable: true }}
          slidesPerView={3}
          navigation={true}
          autoplay={{ delay: 10000, disableOnInteraction: false }}
          modules={[Pagination, Navigation, Autoplay]}
          breakpoints={{
            0: {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            375: {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            575: {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            767: {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            1024: {
              spaceBetween: 10,
              slidesPerView: 3,
            },
          }}
          className=""
        >
          <Row className="gap-5">
            {cardData.map((data, key) => (
              <SwiperSlide key={key} className="pb-5 py-3">
                <Col lg={12} xl={12} md={12}>
                  <Card className=" why-borga-card border-0 p-0 m-0">
                    <Card.Img
                      src={data.image}
                      className="why-borga-card-images"
                    />
                    <Card.Body className="bg-primary card-body">
                      <div className="  rounded-circle icon-bg-container  ">
                        <img
                          style={{ width: "50px", height: "60px" }}
                          className=""
                          src={data.icon}
                          alt="logo"
                        />
                      </div>
                      <Card.Title className="fw-bold text-white mt-4">
                        <div className="d-flex justify-content-end">
                          <div key={key} className="mx-1 py-1">
                            <img
                              width="100px"
                              src={VisaMastercard}
                              alt="logo"
                              style={{ marginTop: "-170px" }}
                            />
                          </div>
                        </div>
                        <span className="card-header-why-borga">
                          {data.title}
                        </span>
                      </Card.Title>
                      <div className="why-borga-body-text">
                        <div
                          className="text-white fw-light"
                          style={{ fontSize: "15px" }}
                        >
                          {data.body}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </SwiperSlide>
            ))}
          </Row>
        </Swiper>
      </Container>
    </div>
  );
};
