import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { tutData } from "./tutData";
import Container from 'react-bootstrap/Container';


interface TutorialData {
  id: number;
  title: string;
  body: string;
}

const Tutorials = () => {


  const [customData, setCustomData] = useState<TutorialData[]>([]);
  const [header, setHeader] = useState<JSX.Element | null>(null);
  const [showPayMerchantButton, setShowPayMerchantButton] = useState(true);
  const [showPayBillsButton, setShowPayBillsButton] = useState(true);
  const [showSendMoneyButton, setShowSendMoneyButton] = useState(false);

  useEffect(() => {
    const data = tutData[0]?.sendmoney || [];
    setCustomData(data);
    setHeader(
      <div className="d-inline tut-header-container text-start">
        <div className="section-text">
          How to send money from{" "}
          <span className="text-info">UK to Africa.</span>
        </div>
      </div>
    );
  }, []);

  const handlePayMerchant = () => {
    setHeader(
      <div className="d-inline tut-header-container text-start">
        <div className="section-text">
          How to pay merchants in{" "}
          <span className="text-info">Africa when in the UK.</span>
        </div>
      </div>
    );
    const data = tutData[1]?.paymerchant || [];
    setCustomData(data);
    setShowPayMerchantButton(false);
    setShowPayBillsButton(true);
    setShowSendMoneyButton(true);
  };

  const handlePayBills = () => {
    setHeader(
      <div className="d-inline tut-header-container text-start">
        <div className="section-text">
          How to pay your bills in{" "}
          <span className="text-info">Africa when in the UK.</span>
        </div>
      </div>
    );
    const data = tutData[2]?.paybills || [];
    setCustomData(data);
    setShowPayMerchantButton(true);
    setShowPayBillsButton(false);
    setShowSendMoneyButton(true);
  };

  const handleSendMoney = () => {
    setHeader(
      <div className="d-inline tut-header-container text-start">
        <div className="section-text">How to send money  from <span className="text-info">UK to Africa.</span></div>
      </div>
    );
    const data = tutData[0]?.sendmoney || [];
    setCustomData(data);
    setShowPayMerchantButton(true);
    setShowPayBillsButton(true);
    setShowSendMoneyButton(false);
  };

  return (
    <React.Fragment>
      <Container className="tut-section">
        <Row className="my-5 tut-section align-items-start">
          <Col className=" d-flex header-section justify-content-start p-0" style={{marginTop:"-45px"}}>{header}</Col>
          <Col lg={"auto"} md={12} className="px-5 nav-col ">
            <div className="d-inline tut-nav-container  justify-content-start ">
              {showPayMerchantButton && (
                <div>
                  <div className="d-flex tut-nav-container  align-items-center ">
                    <div className="tut-nav  ">
                      <div className="fw-bolder tut-nav-text ">
                        How to pay merchants
                      </div>
                      <div className="fw-bolder tut-nav-text ">
                        in Africa when in the UK
                      </div>
                    </div>
                    <Button
                      variant="transparent"
                      className="arrow-btn border-0 p-0 m-0"
                      onClick={handlePayMerchant}
                    >
                      <div className="tut-nav-icon mx-3 fs-3 fw-light border border-black rounded-circle ">
                        <BsArrowRight className="arrow " />
                      </div>
                    </Button>
                  </div>
                </div>
              )}

              {showPayBillsButton && (
                <div className="mt-2">
                  <div className="d-flex tut-nav-container  align-items-center">
                    <div className="tut-nav">
                      <div className="fw-bolder tut-nav-text ">
                        How to pay your bills
                      </div>
                      <div className="fw-bolder tut-nav-text ">
                        in Africa when in the UK
                      </div>
                    </div>
                    <Button
                      variant="transparent"
                      className="arrow-btn border-0 p-0 m-0"
                      onClick={handlePayBills}
                    >
                      <div className="tut-nav-icon mx-3 fs-3 fw-light border border-black rounded-circle ">
                        <BsArrowLeft className="arrow" />
                      </div>
                    </Button>
                  </div>
                </div>
              )}

              {showSendMoneyButton && (
                <div className="mt-2">
                  <div className="d-flex tut-nav-container  align-items-start">
                    <div className="tut-nav">
                      <div className="fw-bolder tut-nav-text ">
                        How to send money
                      </div>
                      <div className="fw-bolder tut-nav-text ">
                        from UK to Africa
                      </div>
                    </div>
                    <Button
                      variant="transparent"
                      className="arrow-btn border-0 p-0 m-0"
                      onClick={handleSendMoney}
                    >
                      <div className="tut-nav-icon mx-5 fs-3 fw-light border border-black rounded-circle ">
                        <BsArrowLeft className="arrow " />
                      </div>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <div>
          <div className="tut-card-container">
            <Row className="gx-5 gy-5 align-items-stretch">
              {customData.length !== 0
                ? customData.map((data: TutorialData, key: number) => (
                    <Col md={12} lg={6} xl={4} key={key}>
                      <Card className="tut-card border-0">
                        <Card.Body className="p-5 tut-card-body">
                          <div className="tut-card-num fw-bolder">
                            {data.id}
                          </div>
                          <div className="tut-card-title">{data.title}</div>
                          <div className="tut-card-body fw-light">
                            {data.body}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : ""}
            </Row>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default Tutorials;
