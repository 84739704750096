import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Back2School() {
  return (
    <Container className="my-5 card border-1 px-5 py-4">
      <h1 className="mb-4 text-uppercase">Back-to-School Remittance Promotion - Terms and Conditions</h1>
      
      <Card className="mb-4 border-0 ">
        <Card.Body>
          <p className='text-decoration-underline fs-5'>
            THESE TERMS AND CONDITIONS APPLY TO GOVERN THE BACK-TO-SCHOOL PROMOTION ON THE BORGA MONEY APP (“THE APP”). 
            THESE TERMS SUPPLEMENT THE GENERAL TERMS AND CONDITIONS OF THE APP.
          </p>
          <p className='text-decoration-underline fs-5'>
            BY PARTICIPATING IN THIS PROMOTION, YOU AGREE THAT SOUVENIRS SHALL BE PROVIDED BASED ON AVAILABILITY AND ON A FIRST-COME, FIRST-SERVED BASIS. 
            IF STOCK RUNS OUT, BORGA MONEY RESERVES THE RIGHT TO OFFER SUBSTITUTES OF EQUIVALENT VALUE, OR NOT AT ALL.
          </p>
        </Card.Body>
      </Card>

      <h2>Promotion Overview</h2>
      <p>
        The Back-to-School Remittance Promotion is an exclusive offer from Borga Money for customers who use the App’s feature that enables customers to pay school fees by remitting funds to schools in Ghana. 
        Eligible customers will receive school essentials and souvenirs for the wards and children of recipients. The promotion also includes special benefits such as Brutaaa rates (lower remittance fees) and faster processing times.
      </p>

      <h2>Promotion Period</h2>
      <p>
        The promotion runs from September 1, 2024, to October 30, 2024. Transactions made outside this period will not qualify for the promotional benefits. 
        Souvenirs are distributed while stocks last, and availability is not guaranteed throughout the entire promotion period.
      </p>

      <h2>Eligibility Criteria</h2>
      <ul>
        <li>You must use the Borga Money App and its feature that enables you to pay school fees to schools in Ghana.</li>
        <li>The minimum qualifying remittance amount is £100 per child.</li>
        <li>The promotion applies to all educational levels in Ghana (primary, secondary, and tertiary).</li>
        <li>Multiple remittances for different children are allowed, provided each transaction meets the minimum amount required.</li>
      </ul>

      <h2>How to Participate</h2>
      <ol>
        <li>Download/Open the Borga Money App: Available on the Google Play Store, Apple App Store, or the Borga Money website.</li>
        <li>Use the feature that allows you to pay school fees on the App: Send money for school fees to Ghana using this specific feature.</li>
        <li>Transaction Confirmation: After completing the transaction, a confirmation email or in-app notification will indicate whether you have qualified for the promotion.</li>
      </ol>

      <h2>Promotion Benefits</h2>
      <ul>
        <li>Brutaaa rates: Receive exclusive lower rates for remittances made through the "pay school fees" feature.</li>
        <li>Faster Processing: School fee transactions are processed faster, ensuring timely payment.</li>
        <li>Souvenirs: Eligible customers will receive souvenirs based on the amount sent, with different tiers offering various gifts.</li>
      </ul>

      <h3>Souvenir Tiers:</h3>
      <ul>
        <li>£100 - £500: Notepad.</li>
        <li>£501 - £999: Notepad + Pen.</li>
        <li>£1,000 - £1,499: Notepad + Pen + Pencil Case.</li>
        <li>£1,500 and above: Notepad + Pen + Pencil Case + Drawstring Bag.</li>
      </ul>

      <h2>Transaction Limits</h2>
      <p>
        The minimum remittance to qualify for souvenirs is £100 per child. There is no upper limit to the amount that can be sent, 
        but larger amounts qualify for more comprehensive souvenir packages. Only transactions made via the feature that allows customers to pay school fees on the App are eligible for the promotion.
      </p>

      <h2>Souvenir Distribution</h2>
      <p>
        Souvenirs will be sent to the children or wards of eligible customers at their school or address, as specified during the remittance process. 
        Delivery timelines may vary depending on the recipient’s location, and customers are responsible for providing accurate recipient information. 
        Souvenirs are non-transferable and cannot be exchanged for cash or other items.
      </p>

      <h2>Processing and Confirmation</h2>
      <p>
        After successfully completing a qualifying transaction, you will receive a confirmation email or notification. 
        This will serve as verification of eligibility for the promotional rates and souvenir benefits. 
        If a confirmation is not received within 48 hours, please contact customer support.
      </p>

      <h2>Data Privacy</h2>
      <p>
        You acknowledge and agree that Borga Money may collect and use personal information in accordance with its privacy policy 
        and applicable data protection laws for the purposes of administering this promotion, including verification of eligibility 
        and delivery of promotional items.
      </p>

      <h2>Fraud Prevention</h2>
      <p>
        Borga Money reserves the right to disqualify any customer from this promotion if there is any suspicion of fraud, 
        manipulation, or misuse of the promotion. Any attempt to engage in fraudulent activity will result in immediate disqualification 
        and may result in further legal action.
      </p>

      <h2>Customer Support</h2>
      <p>
        For any issues or queries regarding the promotion, including transaction problems or souvenir delivery, customers can reach out to Borga Money via:
      </p>
      <ul>
        <li>Email: support@borgamoney.com</li>
        <li>Phone: +44 20 33690013 (8:00am - 5:00pm)</li>
        <li>Live Chat: Accessible on the Borga Money App or website.</li>
      </ul>

      <h2>Disclaimers</h2>
      <p>
        Borga Money reserves the right to modify, suspend, or terminate the promotion at any time without prior notice to you. 
        Souvenir availability may be limited. Where supplies are exhausted, Borga Money may replace the advertised souvenirs with items of equivalent value, if at all. 
        Borga Money makes no warranties, express or implied, regarding the availability of promotional items, the accuracy of information related to the promotion, 
        the performance of third-party services involved in payment processing or delivery, or the suitability of promotional items for any particular purpose. 
        This promotion is not combinable with any other offers or discounts from Borga Money. 
        Borga Money shall not be liable for any failure to fulfill its obligations under this promotion due to circumstances beyond its reasonable control, 
        including but not limited to acts of God, government restrictions, wars, strikes, or interruptions to communication systems.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        ALL DISPUTES ARISING FROM PARTICIPATION IN THE PROMOTION SHALL BE RESOLVED IN ACCORDANCE WITH BORGA MONEY'S TERMS AND CONDITIONS. 
        BORGA MONEY SHALL NOT, UNDER ANY CIRCUMSTANCES, BE HELD LIABLE FOR DELAYS OR LOGISTICAL ISSUES, INCORRECT OR INCOMPLETE RECIPIENT INFORMATION PROVIDED BY CUSTOMERS, 
        LOSS OR DAMAGE TO SOUVENIRS DURING DELIVERY, OR ANY OTHER ISSUES, CLAIMS AND DISPUTES ARISING OUT OF THIS PROMOTION. 
        BY PARTICIPATING IN THIS PROMOTION, YOU ACKNOWLEDGE AND AGREE THAT YOU WAIVE ANY AND ALL RIGHTS TO INITIATE LEGAL PROCEEDINGS, CLAIMS, OR DISPUTES AGAINST BORGA MONEY 
        IN CONNECTION WITH THE PROMOTION, ITS TERMS, OR ANY RELATED MATTERS. THIS WAIVER EXTENDS TO ANY INCIDENTAL ISSUES THAT MAY ARISE DURING OR AFTER THE PROMOTIONAL PERIOD.
      </p>

      <h2>Indemnification</h2>
      <p>
        By participating in this promotion, you agree to indemnify and hold harmless Borga Money, its officers, directors, employees, and agents 
        from any and all claims, damages, liabilities, and costs by third parties arising from your participation in the promotion, 
        including but not limited to any inaccuracies in information provided or any breach of these terms.
      </p>
    </Container>
  );
}

export default Back2School;
