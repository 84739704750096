import Container from 'react-bootstrap/Container';
export const TermsAndConditions = () => {
  return (
    <Container className='card border-1 px-5 my-5'>
      <div className="terms-conditions-section">
        <div
          className="terms-condition-section-header text-start fs-2 pt-5 text-decoration-underline pb-3"
          id="ts-cs"
        >
          BORGA MONEY APPLICATION: TERMS OF USE 
        </div>
        <div className="terms-condition-section-sub-header text-start text-decoration-underline fs-5">
          <p>
            THESE TERMS OF USE, AS AMENDED FROM TIME TO TIME AT THE DISCRETION
            OF BORGA MONEY, APPLY TO GOVERN YOUR ACCESS AND USE OF THE BORGA
            MONEY APP. 
          </p>
          <p>
            BY DOWNLOADING, ACCESSING, USING OR ATTEMPTING TO USE THE APP, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, ACCEPT AND AGREE TO BE BOUND BY THE
            TERMS OF THIS AGREEMENT. 
          </p>
        </div>
        <div className="intro fw-light">
          These terms, along with any forms, receipts, acknowledgements, or
          other documentation completed or used in connection with your use of
          the Services constitute the entire agreement ("Agreement") between you
          ("you" or "your") and Borga Money. 
          <ul>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-1 my-5">
                ABOUT BORGA MONEY 
              </span>
              <div className="point-sub-text  ">
                Borga Money App (‘The App’) is an international remittance
                application which provides the following services (‘the
                Services’): 
                <ul>
                  <li>
                    <span className="text-decoration-underline fw-bold">
                      {" "}
                      Funds Transfer Services which will allow you to: 
                    </span>
                    <ul>
                      <li>
                        transfer available funds from your bank account, debit
                        or credit card(s) or mobile money wallet to a
                        third-party bank account or mobile money wallet; and 
                      </li>
                      <li>process international remittance payments. </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-2 my-5">
                {" "}
                DEFINITIONS 
              </span>
              <div className="point-sub-text  ">
                <div className="py-2">
                  <span className="fw-bold"> “Bank Account(s)”</span> means a
                  bank account you hold with a financial institution; 
                </div>
                <div className="py-2">
                  <span className="fw-bold"> “App”</span> means the Borga Money
                  application;
                </div>
                <div className="py-2">
                  <span className="fw-bold"> “Costs” </span>means administration
                  fees, charges, taxes imposed by the authorities of the country
                  where the funds are transferred, exchange fees or rates and
                  transfer fees. 
                </div>
                <div className="py-2">
                  <span className="fw-bold"> “Credentials” </span>means your
                  username and passwords (including but not limited to any
                  PIN); 
                </div>{" "}
                <div className="py-2">
                  <span className="fw-bold"> “Device”</span> means the device,
                  such as mobile handsets, tablets or any other applicable
                  device, on which you will download the App; 
                </div>{" "}
                <div className="py-2">
                  <span className="fw-bold"> “Force Majeure Event” </span> means
                  any event beyond the reasonable control of the parties. Such
                  events include but are not limited to acts of God, fire, acts
                  of terrorism, natural disasters, infectious diseases, war,
                  civil commotion, embargo, labour dispute, prevention from
                  obtaining raw materials, power failure, acts of government;
                </div>{" "}
                <div className="py-2">
                  <span className="fw-bold">
                    “Intellectual Property Rights” {" "}
                  </span>
                  means copyright (including rights in computer software),
                  patents, trademarks, trade names, service marks, logos,
                  business name (including Internet domain names), design
                  rights, database rights, semi-conductor topography rights,
                  rights in undisclosed or confidential information (such as
                  know-how, trade secrets and inventions (whether patentable or
                  not)) and all other intellectual property or similar
                  proprietary rights of whatever nature (whether registered or
                  not including applications to register or rights to apply for
                  registration) which may now or in the future subsist anywhere
                  in the world; 
                </div>{" "}
                <div className="py-2">
                  <span className="fw-bold"> “PIN” </span> means personal
                  identification number(s); 
                </div>{" "}
                <div className="py-2">
                  <span className="fw-bold"> “Regulation(s)” </span>means the
                  regulations applicable to the Services including, but not
                  limited to, the Applicable Laws of the Republic of Ghana and
                  English laws.
                </div>{" "}
                 
                <div className="py-2">
                  <span className="fw-bold"> “Service” </span>and/or 
                  <span className="fw-bold"> “Services”</span>
                  means any of the service(s) provided by the App as provided in
                  Clause 1 of this Agreement and in compliance with the laws of
                  Ghana and English laws;
                </div>{" "}
                 
                <div className="py-2">
                  <span className="fw-bold"> “SMS”  </span>means short message
                  service. 
                </div>{" "}
                   
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-3 my-5">
                ELIGIBILITY 
              </span>
              <div className="point-sub-text">
                <li>
                  By creating an account, accessing and using the App, you
                  represent and warrant that: 
                  <ul>
                    <li>you are 18 years or older; </li>
                    <li>you have legal capacity; </li>
                    <li>
                      if you are 13 years or older but younger than 18, that you
                      have reviewed these Terms with your parent or legal
                      guardian, and that you and your parent or guardian
                      understand and consent to the terms and conditions of
                      these Terms. If you are a parent or guardian permitting a
                      minor to use these Services, you agree to: 
                      <ul>
                        <li>
                          (a) supervise the minor’s use of these Services;  
                        </li>
                        <li>
                          (b) assume all risk associated with the minor’s use of
                          the Services;   
                        </li>
                        <li>
                          (c) assume any liability resulting from the minor’s
                          use of the Services;
                        </li>
                        <li>
                          (d) ensure the accuracy and truthfulness of all
                          information submitted by the minor and 
                        </li>
                        <li>
                          (e) assume responsibility and be bound by these Terms
                          for the minor’s access and use of these Services;   
                        </li>
                      </ul>
                    </li>
                    <li>
                      all information you supply about your identity and
                      location are true; 
                    </li>
                    <li>
                      you will use the App in accordance with Applicable Laws
                      and regulations; {" "}
                    </li>
                    <li>
                      you will notify Borga Money of any unauthorized access to
                      your account; 
                    </li>
                  </ul>
                </li>
                <li>
                  You agree to notify Borga Money in the event that any of the
                  above information is subject to any change. 
                </li>
                <li>
                   Your registration for the Services is subject to you
                  completing all required information on the registration page
                  in the App and Borga Money accepting your registration. 
                </li>
                <li>
                  You must provide Borga Money at any time with any information
                  that Borga Money may require or may be required by Applicable
                  Laws, the Bank of Ghana, the Bank of England and any
                  regulatory authority or financial partner in order to:  
                  <ul>
                    <li> provide the Services under these Terms; and  </li>
                    <li> verify your identity.  </li>
                  </ul>
                </li>
                <li>
                  Borga Money may reject a registration application to access
                  the Services, or with immediate effect suspend or terminate
                  the Services should you fail to meet or comply with any of the
                  above requirements or the terms of this Agreement. 
                </li>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-4 ">
                ACCOUNT REGISTRATION 
              </span>
              <div className="point-sub-text ">
                <ul>
                  <li>
                    To access the Services on the App, you must be resident in
                    the United Kingdom (UK). 
                  </li>
                  <li>
                    To register as a user, you must provide a registered contact
                    number and an optional email address which shall be verified
                    by a One Time Password (OTP) which will be sent to your
                    provided phone number as an SMS. 
                  </li>
                  <li>
                    Following your input of the OTP, you shall be required to
                    verify your account by providing a national Identification
                    Card number (International passport, UK driver’s license, UK
                    national ID, UK resident permit) 
                  </li>
                  <li>
                    Borga Money provides you the option to use your biometric
                    data as log in details following the verification of your
                    contact number and/or email address. 
                  </li>
                  <li>
                    You will subsequently be required to provide your registered
                    contact number and a six-digit PIN (“your Credentials”) in
                    order to access and use the App. 
                  </li>
                  <li>
                    You will be required to perform a facial scan for liveness
                    check for verification purposes.
                  </li>
                  <li>
                    The facial scan shall be compared with the picture you have
                    provided to ensure that they match.  
                  </li>
                  <li>
                    As part of this process, anti-money laundering (AML) checks
                    will be conducted to identify politically exposed persons
                    (PEPs) other sanction or blacklists.
                  </li>
                  <li>
                    Once you are verified following the ID and AML checks, you
                    shall receive an appropriate risk rating and shall be
                    allowed to perform transactions on the App. If your ID
                    verification fails, you shall be given an option to retry
                    verification. If the ID verification is successful but your
                    ID are flagged based on AML checks, you shall be assigned a
                    risk rating that assigns limits on the transactions you can
                    perform. 
                  </li>
                  <li>
                    You agree and warrant that your username and password
                    shall: 
                    <ul>
                      <li>
                        {" "}
                        be used for the purposes of accessing the Services
                        provided by the App; and 
                      </li>
                      <li>
                        {" "}
                        not be disclosed by you to any unauthorized third
                        party. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    You agree that electronic display pages accessed by entering
                    your Credentials constitute written documents and that
                    clicking or otherwise electronically activating “OK”,
                    “submit”, “continue”, “approve”, or similar areas of such
                    pages according to on-screen instructions or otherwise
                    submitting instructions electronically shall have the same
                    legal effect as if you had signed by hand and delivered hard
                    copy versions of such pages to Borga Money with intent to be
                    legally bound by their contents. You agree that once such
                    instructions are clicked or submitted, Borga Money shall
                    have no obligation to reverse them.  
                  </li>{" "}
                  <li>
                    You agree to link a valid Bank Account, Mobile Money
                    Account(s) and/or any other valid payment account(s) to your
                    registered Borga Money account. 
                  </li>
                  <li>
                    You agree that Borga Money may accept and act upon any
                    instructions provided to us through the App when your
                    Credentials have been used to access the App, whether or not
                    it is you who accessed the App.  {" "}
                  </li>
                  <li>
                    When using the App, you authorize Borga Money to access your
                    Bank Account(s), Mobile Money account(s) and other connected
                    account(s) by accessing the online system of your Bank(s)
                    and service provider(s) and corresponding Credentials in
                    order to register, access,retrieve and download the Bank
                    Account and Mobile Money or other information.
                  </li>
                  <li>
                    You are responsible for maintaining the secrecy and security
                    of your Credentials. You should not disclose your
                    Credentials to any third party. If any of your Credentials
                    is compromised, you must notify Borga Money immediately in
                    order for Borga Money to suspend or block your access to the
                    Services and prevent any unauthorized transactions. Borga
                    Money shall not be liable for any fraudulent or unauthorized
                    transaction on your account notwithstanding that you
                    notified Borga Money that your Credentials have been
                    compromised, the Services have been used fraudulently by an
                    unauthorized person or that your Device is stolen, missing
                    or lost when the said unauthorized usage or transaction(s)
                    are attributable to your negligence, bad faith or fraud. 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-5 ">
                NOTIFICATIONS 
              </span>
              <div className="point-sub-text">
                You may receive notifications from Borga Money in relation to
                Transactions either by email and SMS. We will provide you with
                information after receipt of a Payment Instruction enabling you
                to identify the Transaction, along with details of the amount of
                the Transaction in the currency used in the Payment Instruction,
                our Service Fee, exchange rate and the date on which the Payment
                Instruction was received.  
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-6 ">
                USERS 
              </span>
              <div className="point-sub-text">
                There shall be four categories of users: 
                <ul>
                  <li>
                    Sender/Payer: they may transmit money to Recipient(s) in
                    Ghana or make payment for the purchase goods and services to
                    Merchants or Billers in Ghana on the App;
                  </li>
                  <li>
                    {" "}
                    Recipient: they may receive terminated funds in Ghana from a
                    Sender/Payer in the UK on the App. 
                  </li>
                  <li>
                    {" "}
                    Biller/Payee: they may receive terminated funds in Ghana
                    from a Sender/Payer in the UK for the purchase of services
                    on the App. 
                  </li>
                  <li>
                    {" "}
                    Merchants: they are businesses verified by Borga Money that
                    have partnered with Borga Money to provide goods and
                    services for sale. A Merchant is verified on the App when it
                    supplies Borga Money with the following documents: 
                    <ul>
                      <li>(a) Business Incorporation documents. </li>
                      <li>
                        (b) Current permit to operate in their industry.  
                      </li>
                      <li>
                        (c) Any other licenses/approvals the business may
                        require.  
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-7 ">
                COMPLIANCE  
              </span>
              <div className="point-sub-text">
                There shall be four categories of users: 
                <ul>
                  <li>
                    You agree that neither the App nor Services will be used for
                    illegal, fraudulent or unethical purposes or for purposes
                    which could affect the security or integrity of the App, the
                    Services, other Borga Money users or customers or any other
                    Borga Money systems, hardware or software. 
                  </li>
                  <li>
                    You will not use the App in a manner that infringes another
                    person’s rights or interests, including any intellectual
                    property rights or any other personal or proprietary rights
                    of any third party. 
                  </li>
                  <li>
                    Borga Money reserves the right to deny or restrict access to
                    the App, or some or all of the Services under certain
                    circumstances, or to reject any transaction in the following
                    cases without any liability: 
                    <ul>
                      <li>
                        {" "}
                        if any of your assets are attached or assigned by a
                        legal order; 
                      </li>
                      <li>
                        {" "}
                        if any of your assets are attached or assigned by a
                        legal order; 
                      </li>
                      <li>
                        {" "}
                        if in the judgment of Borga Money, any activity on any
                        of your accounts, or your use of App, or any one or more
                        of the Services, is for illegal, unethical or fraudulent
                        purposes; 
                      </li>
                      <li>
                        {" "}
                        if in the judgment of Borga Money, access to your
                        account, App or more generally the Services is being
                        performed fraudulently or without your consent; 
                      </li>
                      <li>
                        {" "}
                        if access to the App and/or the Services is being used
                        to attempt to obtain unauthorized access to Borga
                        Money’s other users or customers, systems, hardware or
                        software; 
                      </li>
                      <li>
                        {" "}
                        if you are in breach of this Agreement (including breach
                        of warranties); 
                      </li>
                      <li>
                        if your use of the Services is suspected to breach or
                        actually breaches any Applicable Law or Regulation;
                        and/or 
                      </li>
                      <li>
                        in order to comply with any Applicable Law and
                        Regulation. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    Where you request reinstatement of access to the App or any
                    of the Services, Borga Money may require that you take
                    certain steps or comply with any requirements determined by
                    Borga Money prior to being re-granted access. Such steps may
                    include but are not limited to an update of the security
                    software on your Device, starting the onboarding process
                    again and providing additional and/or new identification.   
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-8 ">
                LIMITS 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Borga Money may, at its discretion and without prior notice,
                    refuse a request for authorization of any transaction and
                    may notify third parties of such refusal as it deems
                    necessary. Borga Money may refuse to honour any payment
                    and/or funds transfer request if there are insufficient
                    funds in your account(s).
                  </li>
                  <li>
                    Borga Money Ltd may impose limits on the amount of money you
                    use or number of transactions that you may execute in a
                    period of time.  
                  </li>
                  <li>
                    When using the Services for the purchase of goods, you
                    acknowledge that: 
                    <ul>
                      <li>
                        the purchase of goods is contingent on the Merchant(s): 
                        <ul>
                          <li>
                            (a) accepting to provide the goods as requested;
                            and 
                          </li>
                          <li>
                            (b) delivering the goods upon completion of the
                            transaction on the App.  
                          </li>
                        </ul>
                      </li>
                      <li>
                        {" "}
                        Borga Money shall not be held liable for the delay or
                        inability of a Merchant to deliver goods in event of
                        delay or non-delivery, or for the negligence of a
                        Merchant in the event of delivery of defective goods by
                        a Merchant. 
                      </li>
                      <li>
                        Where there is a delay, inability to deliver goods or
                        the delivery of defective goods by a Merchant under
                        Clause 7.3(ii) above, Borga Money shall refund the
                        principal transfer amount less Costs to your Bank
                        Account or other payment account.  
                      </li>
                      <li>
                        {" "}
                        You agree that all disputes, controversies or claims
                        arising from such delay, delivery of defective goods
                        and/or inability of a Merchant to deliver under Clause
                        7.3(iii) shall be made in absolute compliance with
                        Clause 19. 
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-9 ">
                FEES  
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    You agree to pay the fees that may be applicable to your use
                    of the Services as determined by Borga Money from time to
                    time. 
                  </li>
                  <li>
                    You agree that each transaction amount may include fees,
                    charges, electronic transfer levy (e-levy) and value added
                    tax (VAT) payable on such transaction and it is your
                    responsibility, prior to approving the processing of a
                    transaction, to review the amount(s) of such transaction.
                  </li>
                  <li>
                    Borga Money reserves the right to amend or change any fee or
                    charge applicable to the Services. All such amendments and
                    changes shall be displayed on the App. {" "}
                  </li>
                  <li>
                    You are solely responsible for keeping up to date with any
                    changes in fees and charges. Borga Money will not be liable
                    for any losses, costs, damages or any other form of damage
                    caused by its changes to fees or charges. {" "}
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-10 ">
                STATEMENTS  
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Transactions processed with your account(s) will be listed
                    in the App. It is advised that you check your transaction
                    history on a regular basis. 
                  </li>
                  <li>
                    A statement shall be made available to you by Borga Money
                    for a given period upon request from you. The statement
                    shall detail your transaction history, the details of each
                    transaction with corresponding date of completion, fees and
                    charges (if any). 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-11 ">
                PAYMENTS  
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    You also authorize Borga Money to follow the payment
                    instructions that it receives from you through the App.  
                  </li>
                  <li>
                    When the App receives a payment instruction, you authorize
                    Borga Money to debit or charge your Bank Account or other
                    payment account on your behalf. 
                  </li>
                  <li>
                    You agree that where funds sent for the purchase of goods,
                    Borga Money shall debit your payment account with the total
                    cost of the goods together with all costs and taxes, and the
                    said total amount kept in escrow until delivery is confirmed
                    by the delivering Merchant, following which the appropriate
                    funds shall be released to the Merchant. 
                  </li>
                  <li>
                    Borga Money shall not incur any liability if it is unable to
                    complete any payments or funds transfers initiated by you
                    for any reason beyond Borga Money’s control, including the
                    existence of any one or more of the following
                    circumstances: 
                    <ul>
                      <li>
                        {" "}
                        You do not have sufficient funds to complete the
                        transaction; 
                      </li>
                      <li>
                        {" "}
                        You were notified by Borga Money that the App’s payment
                        processing center was not functioning properly prior to
                        your initiation of the transaction; 
                      </li>
                      <li>
                        {" "}
                        You have not provided Borga Money with the correct
                        information, or the information that you provided
                        becomes incorrect (e.g., a new payment card with a new
                        card number); and/or 
                      </li>
                      <li>
                        {" "}
                        Circumstances beyond the reasonable control of Borga
                        Money occur, whether foreseeable or foreseen, that
                        prevent the proper execution of the payment. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    Borga Money reserves the right to refuse to transfer funds
                    from your Bank account or Wallet or to process payments for
                    goods and services if the information required in relation
                    to the transaction is inaccurate or incomplete. Borga Money
                    shall not be held liable for any loss(es) you may incur as a
                    result of such refusal.  
                  </li>
                  <li>
                    When a transaction instruction is received by Borga Money,
                    you shall receive a notification on the outcome of the
                    transaction (acceptance or rejection) together with a
                    reference number of the transaction, the details of the
                    Recipient or Biller/Payee and a breakdown of any applicable
                    charges and fees. {" "}
                  </li>
                  <li>
                    You will bear all the costs associated with the use of the
                    App, and such costs shall include without limitation,
                    administration fees, charges, taxes imposed by the
                    authorities of the country where the funds are transferred,
                    exchange fees and transfer fees (the “Costs”). {" "}
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-12 ">
                  FOREIGN EXCHANGE 
              </span>
              <div className="point-sub-text">
                Transfers and payments initiated from the UK in British Pound
                Sterling (GBP) or any other currency which is the legal tender
                in the country of the Sender/Payer and made to Ghana will be
                made in Ghana Cedis (GH¢) at the prevailing rate as provided in
                the App and determined by Borga Money from time to time. The
                currency will be converted at the time of transfer and the
                Recipient or Biller/Payee will receive the Ghana Cedi (GH¢)
                amount shown on the transaction form. 
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-13 ">
                REFUNDS
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    In the event that an initiated transfer of funds cannot be
                    terminated within twenty-four (24) hours of the initiation
                    of the transaction for any reason (including but not limited
                    to due to a restriction placed by any  authority), Borga
                    Money will refund the principal transfer amount less Costs
                    (as defined in Clause 2 herein) to your Bank Account or
                    other payment account. 
                  </li>
                  <li>
                    Where, at the discretion of Borga Money, any transaction is
                    suspected to be or is flagged as fraudulent or otherwise
                    illegal in nature, Borga Money shall either hold the funds
                    in trust or refund the principal transfer amount less Costs
                    to your Bank Account or other payment account and shall
                    suspend your Account pending the determination of the
                    legality or otherwise of your transaction(s). 
                  </li>
                  <li>
                    Where you have completed a payment transaction on the App,
                    but the delivering Merchant is unable to complete delivery
                    of the purchased goods due to the unavailability of the
                    person(s) you have designated to receive delivery, or for
                    any other reason not attributable to the negligence of the
                    Merchant, Borga Money shall refund the principal transfer
                    amount less Costs to your Bank Account or other payment
                    account. 
                  </li>

                  <li>
                    {" "}
                    Save Clause 12.1, Borga Money may charge an inconvenience
                    fee for refunding transactions under this Clause 12. 
                  </li>
                  <li>
                    Notwithstanding any of the above, Borga Money shall not be
                    obliged to refund a transfer to you in any of the following
                    circumstances: 
                    <ul>
                      <li>
                        where Borga Money has completed a transaction in
                        accordance with instructions and information provided by
                        you, and such information was incorrect or contained
                        errors of any kind; or 
                      </li>
                      <li>
                        {" "}
                        where Borga Money can show that payment was in fact
                        received by the Recipient or Biller/Payee. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    Where the transaction is not completed by reason of an error
                    on the App, Borga Money shall apply its best efforts to
                    resolve the error at the earliest time possible and shall
                    see to the completion of such transaction(s). 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-14 ">
                RIGHT TO DELAY AND REFUSE 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Borga Money shall not incur any liability and may delay or
                    refuse to process any attempted transaction at any time for
                    any of the reasons set out in Clause 10 in addition to the
                    following: 
                    <ul>
                      <li>
                        {" "}
                        Borga Money reasonably believes that it does not have
                        your consent to process the attempted transaction; 
                      </li>
                      <li>
                        {" "}
                        there is a dispute (which Borga Money reasonably
                        believes is genuine) about who owns, or is entitled to,
                        any money in your Bank Account; 
                      </li>
                      <li>
                        {" "}
                        Borga Money reasonably believes that processing of your
                        Instruction would breach the terms of this Agreement; 
                      </li>
                      <li>
                        {" "}
                        there are insufficient funds to make the payment and/or
                        to cover the Costs that may apply in processing the
                        transaction; and 
                      </li>
                      <li>
                        {" "}
                        Borga Money has suspended or blocked your use of the
                        App. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    Borga Money may charge an inconvenience fee for refusing any
                    payment or Instruction under this Clause 13. Where such a
                    fee applies, it will be set out in the App. 
                  </li>
                  <li>
                    You agree that Borga Money shall not be held liable for
                    damages in relation to: 
                    <ul>
                      <li>
                        {" "}
                        the provision of any incorrect information when
                        processing the transaction; 
                      </li>
                      <li>
                        {" "}
                        any delay in the processing of a transaction initiated
                        through the App, and resulting without limitation from
                        events such as the transaction exceeding the authorized
                        amount transfer or is restricted pursuant to any allowed
                        per Applicable Laws and Regulations, nor for; 
                      </li>
                      <li>
                        {" "}
                        a failure to complete a transaction whether caused by
                        the gross negligence of Borga Money and its employees,
                        agents or representative except for the refund of the
                        principal amount of the transaction less Costs. 
                      </li>
                    </ul>
                  </li>

                  <li>
                    Borga Money may suspend, restrict or stop your use of the
                    App if it reasonably thinks it is necessary for the
                    following reasons: 
                    <ul>
                      <li>
                        {" "}
                        Borga Money is of the opinion that the security of your
                        Bank Account(s) is at risk; 
                      </li>
                      <li>
                        {" "}
                        Borga Money suspects unauthorized or fraudulent use of
                        your Credentials; 
                      </li>
                      <li> any of the situations in Clause 6.3 occurs; or  </li>
                      <li> a Force Majeure Event occurs. </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-15 ">
                LIMITATION OF LIABILITY 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Borga Money shall have no liability beyond the repayment of
                    the principial amount of any transaction under this
                    Agreement less Costs with regards to the use of the App
                    and/or any failure by Borga Money. 
                  </li>
                  <li>
                    You agree that Borga Money shall not be held liable for
                    damages of any kind resulting from or caused by: 
                    <ul>
                      <li>
                        {" "}
                        any use by you of the App or the Services for a
                        commercial, business or resale purpose. In particular,
                        and without limiting this exclusion, Borga Money shall
                        will have no liability to you for any loss of profit,
                        loss of business, business interruption, or loss of
                        business opportunity; or 
                      </li>
                      <li>
                        a failure to complete a transaction whether caused by
                        the gross negligence of Borga Money, its employees,
                        agents or representatives except for the refund of the
                        principal amount of the transaction less Costs. 
                      </li>
                    </ul>
                  </li>
                  <li>
                    Borga Money shall not be liable for any loss or damage
                    arising from your use of the App to the extent that the
                    financial institution in which you have a Bank Account is
                    responsible for that loss or damage. 
                  </li>

                  <li>
                    Borga Money shall not be liable for any losses or damages
                    incurred by reason of any delay or inability of a Merchant
                    to deliver goods. 
                  </li>
                  <li>
                     Borga Money shall not be liable for any losses or damages
                    incurred by reason of the delivery of defective goods by any
                    Merchant. All such liability shall be borne wholly and
                    absolutely by the Merchant(s). 
                  </li>
                  <li>
                    Borga Money shall not be liable for any loss or damage
                    caused as a result of unauthorized access to the App or any
                    of your Bank Accounts if: 
                    <ul>
                      <li>
                        you intentionally or negligently failed to take all
                        reasonable precautions to protect your Credentials or
                        any Device used to access the Services; 
                      </li>
                      <li>
                        you failed to notify us that the Service was being
                        accessed in an unauthorized way after becoming aware of
                        it; or 
                      </li>
                      <li>you acted fraudulently. </li>
                    </ul>
                  </li>
                  <li>
                    If you suspect that a payment from one of your Bank
                    Account(s) using the App was not authorized or was
                    incorrect, you must contact the financial institution
                    holding the Bank Account from which the payment was made as
                    soon as possible.  
                  </li>
                  <li>
                    Borga Money shall not be liable to you for any loss of
                    profits, loss of business, or any indirect, consequential,
                    special or punitive losses incurred due to any failures of
                    Borga Money or the App. 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-16 ">
                DISCLAIMER OF WARRANTIES 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    THE SERVICES PROVIDED UNDER THIS AGREEMENT AND RELATED
                    DOCUMENTATION ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY
                    KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT
                    LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
                    FITNESS FOR A PARTICULAR PURPOSE. 
                  </li>
                  <li>
                    You represent and agree that all information you provide is
                    accurate, current, and complete. You agree not to
                    misrepresent your identity or Bank Account information. You
                    agree to keep the Bank Account information secure, up to
                    date and accurate.  
                  </li>
                  <li>
                    You represent that you are the legal owner, or authorized
                    user of your Bank Account(s) and/or other payment
                    accounts.  
                  </li>

                  <li>
                    Borga Money shall not be liable for any losses or damages
                    incurred by reason of any delay or inability of a Merchant
                    to deliver goods. 
                  </li>
                  <li>
                    You grant Borga Money and its partners the right to access
                    information and online accounts of the Bank Account(s) you
                    have with financial institutions and to contact such
                    financial institutions on your behalf where necessary for
                    the fulfilment of its obligations under this Agreement.
                  </li>
                  <li>
                    You authorize Borga Money and its partners to use such
                    information and access, including Credentials or other
                    content you provide, to retrieve data on your behalf and
                    process transactions for purposes of providing the Services
                    under this Agreement. Third-party sites shall be entitled to
                    rely on the authorization granted by you or through your
                    Bank Account. 
                  </li>
                  <li>
                    You agree that prices of goods and services on the App may
                    vary based on parameters including, but not limited to,
                    price(s) of goods and services as provided by different
                    Merchants and the distance between the pick-up point and the
                    delivery location.  
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-17 ">
                INTELLECTUAL PROPERTY RIGHTS
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Borga Money owns the contents and/or compilation of the
                    contents of the App and its Services, including any
                    material, information, data compilations, software, icons,
                    text, graphics, lay-outs, images, sound clips,
                    advertisements, video clips, digital downloads, trade names,
                    logos, trade-marks, designs and service marks (“Our
                    Content”). 
                  </li>
                  <li>
                    Our Content is protected by law, including but not limited
                    to intellectual property laws. You do not acquire any right,
                    title or interest in Our Content. You shall not use,
                    distribute, reproduce, republish, install, transmit, modify,
                    adapt, store, post, copy, screenshot or record our content
                    without our prior permission unless expressly authorized in
                    these Terms. 
                  </li>
                  <li>
                    You own the information you submit to Borga Money as part of
                    your registration, and any data, text, pictures and other
                    materials that you upload, post, submit, store, send,
                    receive or share on the App (“Your Content”). We do not
                    claim any intellectual property rights to Your Content.  
                  </li>

                  <li>
                    Notwithstanding any provision in these Terms and subject to
                    our Privacy Policy, you grant Borga Money non-exclusive,
                    worldwide, transferable, perpetual, irrevocable right to
                    use, distribute, reproduce, republish, install, transmit,
                    modify, adapt, store, post, copy, display, communicate and
                    retain copies of Your Content in connection with the
                    Service, marketing, advertising, and other purposes.  
                  </li>
                  <li>
                    You represent and warrant that your use of the Service or
                    any portion thereof, Your Content and the right you grant
                    Borga Money under this clause does not infringe the rights
                    of any person or breach any contract or legal duty owed to
                    any person. 
                  </li>
                  <li>
                    Any third party registered or unregistered intellectual
                    property on the App (“Third Party Content”) is owned and
                    controlled by that third party. Borga Money does not grant
                    any license in respect of Third-Party Content. Your use of
                    any Third Party Content is subject to these Terms and any
                    terms of the Third Party. 
                  </li>
                  <li>
                    You agree that prices of goods and services on the App may
                    vary based on parameters including, but not limited to,
                    price(s) of goods and services as provided by different
                    Merchants and the distance between the pick-up point and the
                    delivery location.  
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-18 ">
                TERM AND TERMINATION 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    This Agreement shall become binding once you select “I
                    Accept” and shall continue unless terminated pursuant to
                    this Clause.  
                  </li>
                  <li>
                    You may terminate this Agreement at any time by deleting
                    your account and ceasing your use of the Services or the
                    App. 
                  </li>
                  <li>
                    You may terminate this Agreement at any time by deleting
                    your account and ceasing your use of the Services or the
                    App. {" "}
                  </li>
                  <ul>
                    <li>
                      {" "}
                      if any of your assets are attached or assigned by a legal
                      order; 
                    </li>
                    <li>
                      {" "}
                      Borga Money is required to do so by court order or
                      Applicable Law; 
                    </li>
                    <li>
                      {" "}
                      if in the judgment of Borga Money, any activity on any of
                      the App or any of the Services, is for illegal or
                      fraudulent purposes; 
                    </li>
                    <li>
                      {" "}
                      if in the judgment of Borga Money, access to the App or
                      the Services is being performed fraudulently or without
                      your consent; 
                    </li>
                    <li>
                      {" "}
                      if access to the App, and/or the Services is being used to
                      attempt or to obtain unauthorized access to Borga Money
                      systems, hardware or software; 
                    </li>
                    <li>
                      {" "}
                      you are in breach of this Agreement, including breach of
                      warranties; 
                    </li>
                    <li>
                      {" "}
                      your use of the Services is suspected to breach or
                      breaches any Applicable Law;  
                    </li>
                    <li>
                      {" "}
                      in order to comply with any Applicable Law and
                      Regulation; 
                    </li>
                    <li> for convenience; </li>
                    <li>
                      {" "}
                      for any other reasonable cause as determined by Borga
                      Money. 
                    </li>
                  </ul>
                  <li>
                    Borga Money may, at its sole discretion, reinstate the
                    Services if it is satisfied that you have rectified any
                    breach of this Agreement, or the reason for the initial
                    suspension of the Service no longer applies. 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-19 ">
                APPLICABLE LAW & JURISDICTION 
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    These Terms and any dispute between you or your affiliates,
                    predecessors, successors, assigns, employees and agents and
                    Borga Money shall be governed by the laws of Ghana
                    irrespective of the rules of conflict of laws.  
                  </li>
                  <li>
                    Any dispute arising out of these terms and conditions shall
                    be settled by arbitration in accordance with the laws of
                    Ghana and Ghana Arbitration Centre Rules. The seat for the
                    arbitration will be Accra, Ghana. The language for the
                    arbitration shall be English. There shall be a sole
                    arbitrator which shall be appointed by the Ghana Arbitration
                    Centre. 
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-20 ">
                DISPUTES 
              </span>
              <div className="point-sub-text ">
                <ul>
                  <li>
                    Borga Money shall be under no obligation to participate in
                    any dispute between users of our Services. Borga Money may
                    however elect to mediate controversies and disputes between
                    users arising under this Agreement. 
                  </li>
                  <li>
                    Where a dispute arises, Borga Money shall provide a
                    deliberation forum on the App between the disputing parties
                    to enable negotiation and deliberation between the disputing
                    parties.  
                  </li>
                  <li>
                    You agree that under no circumstance shall Borga Money be
                    made a party to a legal suit or claim commenced by you with
                    regards to a dispute arising out of your use of this App or
                    the Services. {" "}
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-21 ">
                NOTIFICATIONS
              </span>
              <div className="point-sub-text">
                <ul>
                  <li>
                    Notifications from users of the Services may be sent to: 
                    <ul>
                      <li>
                        Phone :{" "}
                        <span className="fw-bold ">
                          {" "}
                          +44 20 33690013 (8:00am - 5:00pm)
                        </span>
                      </li>
                      <li>
                        Email :{" "}
                        <span className="fw-bold ">
                          {" "}
                          support@borga.money
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-5">
              <span className="text-decoration-underline fw-bold point-22 ">
                SEVERABILITY
              </span>
              <div className="point-sub-text ">
                If any provision of these Terms is held by an adjudicatory body
                of competent jurisdiction to be invalid, the remaining
                provisions shall remain effective and shall not be affected.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};
