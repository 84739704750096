import React from 'react'
import {  Card, Col, Row } from "react-bootstrap";
import { FaqCardData } from "./faqData";
import Container from 'react-bootstrap/Container';

export const ContactUs = () => {
  return (
    <section id="contact-us">
    <Container className="mb-5">
    <div className="faqs-header text-center" >
      Contact the Borga Team{" "}
    </div>
    <Row className="align-items-stretch gy-2 gx-3 m-auto justify-content-md-center row-cols-lg-4  row-cols-md-2 row-cols-sm-1 row-cols-xs-1">
      {FaqCardData.map((data, key) => (
        <Col sm={6} key={key}>
          <Card className="rounded-4 faqs-cards">
            <div className="faqs-icon-container my-4 mx-4 p-2 rounded-3">
              {data.icon}
            </div>
            <div className="fw-bolder px-4 fs-5 mb-2 mt-4">
              {data.title}
            </div>
            <div className="fw-light faqs-card-body px-4 mb-2">
              {data.body}
            </div>
            <div className="px-4 mb-3 fw-bold info-text text-decoration-underline">
              <a className="text-primary" href={data.link}>
                {data.info}
              </a>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
    </Container>
  </section>
  )
}
